import { Grid } from '@krakentech/coral';
import { IconHome } from '@krakentech/icons';

import { ActionCard } from '@/components';
import { useHasActiveSocialAgreement } from '@/hooks/accounts/useHasActiveSocialAgreement';
import { useHasActiveWatersureAgreement } from '@/hooks/accounts/useHasActiveWatersureAgreement';
import { INTERNAL_PATHS } from '@/utils/urls';

const HomeMoveCard = () => {
  const actionCardHref = INTERNAL_PATHS.MOVE_HOME[0].path;

  const {
    data: hasActiveWatersureAgreement,
    isLoading: isHasActiveWatersureAgreementLoading,
    isError: isHasActiveWatersureAgreementError,
  } = useHasActiveWatersureAgreement();

  const {
    data: hasActiveSocialAgreement,
    isLoading: isHasActiveSocialAgreementLoading,
    isError: isHasActiveSocialAgreementError,
  } = useHasActiveSocialAgreement();

  const isSpecialTariffLoading =
    isHasActiveWatersureAgreementLoading || isHasActiveSocialAgreementLoading;
  const isSpecialTariffError =
    isHasActiveWatersureAgreementError || isHasActiveSocialAgreementError;

  if (
    isSpecialTariffLoading ||
    isSpecialTariffError ||
    hasActiveSocialAgreement ||
    hasActiveWatersureAgreement
  )
    return null;

  return (
    <Grid.Item>
      <ActionCard
        href={actionCardHref}
        title="Move out of home"
        titleComponent="p"
        icon={<IconHome size={32} />}
      />
    </Grid.Item>
  );
};

export default HomeMoveCard;
