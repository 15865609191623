import {
  Button,
  Stack,
  Typography,
  useCoralBreakpoints,
} from '@krakentech/coral';
import { addDays, isFuture } from 'date-fns';

import PaymentFrequencyTooltip from '@/components/billingAndPayment/PaymentPlanCard/components/PaymentFrequencyTooltip';
import UpcomingPaymentSchedule from '@/components/billingAndPayment/UpcomingPaymentSchedule';
import BoxWithGreyBorder from '@/components/BoxWithGreyBorder';
import MutedText from '@/components/MutedText';
import { PAYMENT_FREQUENCY } from '@/consts/paymentFrequency';
import { usePaymentSchedulesContext } from '@/context';
import { useHasActiveSocialAgreement } from '@/hooks/accounts/useHasActiveSocialAgreement';
import { useCurrentActivePaymentSchedule } from '@/hooks/billsAndPayments/useCurrentActivePaymentSchedule';
import { useUpcomingPaymentSchedule } from '@/hooks/billsAndPayments/useUpcomingPaymentSchedule';
import { useSyncFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';
import { makeDateKrakenFriendly } from '@/utils/formatters/date';
import { cardinalNumberToOrdinalNumber } from '@/utils/formatters/number';
import { getPaymentFrequency } from '@/utils/paymentSchedule';
import { INTERNAL_PATHS } from '@/utils/urls';

const PaymentFrequency = () => {
  const changePaymentScheduleEnabled = useSyncFeatureFlag(
    FeatureNames.ChangePaymentSchedule
  );
  const { isMaxMd } = useCoralBreakpoints();
  const {
    currentActivePaymentSchedule: {
      isVariablePaymentAmount,
      paymentFrequencyMultiplier,
      paymentFrequency,
      paymentDay,
    },
  } = usePaymentSchedulesContext();

  const {
    isLoading: isLoadingCurrentPaymentSchedule,
    error: isErrorCurrentPaymentSchedule,
    data: currentPaymentSchedule,
  } = useCurrentActivePaymentSchedule();

  const { data: hasActiveSocialAgreement } = useHasActiveSocialAgreement();

  const validToDate = currentPaymentSchedule?.node?.validTo;

  // Use `makeDateKrakenFriendly` to ensure `activeOnDate` is in the correct format
  const activeOnDate =
    validToDate && !isNaN(new Date(validToDate).getTime())
      ? makeDateKrakenFriendly(addDays(new Date(validToDate), 1))
      : undefined;

  const {
    isLoading: isLoadingUpcomingPaymentSchedule,
    error: isErrorUpcomingPaymentSchedule,
    data: upcomingPaymentSchedule,
  } = useUpcomingPaymentSchedule({
    activeOnDate,
  });

  const isPaymentScheduleNotLoading =
    isLoadingCurrentPaymentSchedule || isLoadingUpcomingPaymentSchedule;

  const isPaymentScheduleError =
    isErrorCurrentPaymentSchedule || isErrorUpcomingPaymentSchedule;

  const { validFrom } = upcomingPaymentSchedule?.node || {};

  const upcomingPaymentChange =
    !isPaymentScheduleNotLoading &&
    !isPaymentScheduleError &&
    validFrom &&
    isFuture(validFrom);

  const showChangeButton =
    changePaymentScheduleEnabled &&
    !upcomingPaymentChange &&
    !hasActiveSocialAgreement;

  const getPaymentFrequencyText = () => {
    if (isVariablePaymentAmount) {
      return 'When you get your bill';
    } else {
      return `Every ${getPaymentFrequency(
        paymentFrequency,
        paymentFrequencyMultiplier
      )}${
        paymentFrequency === PAYMENT_FREQUENCY.MONTHLY && paymentDay
          ? ` on the ${cardinalNumberToOrdinalNumber(paymentDay)}`
          : ''
      }`;
    }
  };

  return (
    <BoxWithGreyBorder>
      <Stack direction="vertical">
        <Stack
          {...(isMaxMd && { direction: 'vertical' })}
          justifyContent={isMaxMd ? 'center' : 'space-between'}
          alignItems="center"
        >
          <Stack
            direction="vertical"
            gap="xs"
            alignItems="center"
            md={{
              alignItems: 'flex-start',
              direction: 'vertical',
              gap: 'xs',
            }}
          >
            <Stack gap="xs">
              <MutedText variant="h3">You pay:</MutedText>
              <PaymentFrequencyTooltip
                isVariablePaymentAmount={isVariablePaymentAmount!}
                paymentFrequency={paymentFrequency!}
                paymentFrequencyMultiplier={paymentFrequencyMultiplier!}
              />
            </Stack>
            <Typography>{getPaymentFrequencyText()}</Typography>
          </Stack>
          {showChangeButton && (
            <Button
              variant="outlined"
              {...(isMaxMd && { fullWidth: true })}
              href={INTERNAL_PATHS.CHANGE_PAYMENT_SCHEDULE.path}
            >
              Change
            </Button>
          )}
        </Stack>
        {changePaymentScheduleEnabled && upcomingPaymentChange && (
          <UpcomingPaymentSchedule />
        )}
      </Stack>
    </BoxWithGreyBorder>
  );
};

export default PaymentFrequency;
