import * as yup from 'yup';

export const obtainKrakenTokenSchema = yup.object({
  obtainKrakenToken: yup.object({
    token: yup.string().required(),
    payload: yup.object().required(),
    refreshToken: yup.string().required(),
    refreshExpiresIn: yup.number().optional(),
  }),
});

export const requestPasswordResetSchema = yup.object({
  requestPasswordReset: yup.object({
    email: yup.string().required(),
  }),
});

export const resetPasswordSchema = yup.object({
  resetPassword: yup.object({
    clientMutationId: yup.string().nullable(),
    errors: yup
      .array(
        yup.object({
          field: yup.string(),
          errors: yup
            .array(
              yup.object({
                code: yup.string().nullable(),
                message: yup.string().nullable(),
              })
            )
            .nullable(),
        })
      )
      .nullable(),
  }),
});

export const loginWithPreSignedKeySchema = yup.object({
  obtainKrakenToken: yup.object({
    token: yup.string().required(),
  }),
});

export const authWithPreSignedKeySchema = yup.object({
  obtainKrakenToken: yup.object({
    token: yup.string().required(),
  }),
});

export const accountIdentificationSchema = yup.object({
  blackholeEmailAccountUser: yup.object({
    hasMobileNumber: yup.boolean().required(),
    hasDateOfBirth: yup.boolean().required(),
    accounts: yup
      .array(
        yup.object({
          accountUserNames: yup
            .array(
              yup.object({
                givenName: yup.string().required(),
                familyName: yup.string().required(),
                id: yup.string().required(),
              })
            )
            .required()
            .min(1),
        })
      )
      .required(),
  }),
});

export const verifyIdentitySchema = yup.object({
  verifyIdentity: yup.object({
    token: yup.string().required(),
  }),
});

export const verifyRegisterUserSchema = yup.object({
  updateEmailAndPassword: yup
    .object({
      success: yup.boolean().isTrue().required(),
    })
    .required(),
});

export const userRegistrationStatusSchema = yup.object({
  blackholeEmailAccountUser: yup.object({
    email: yup.string().nullable(),
  }),
});

export const forceReauthenticationSchema = yup.object({
  forceReauthentication: yup.object({
    tokensInvalidated: yup.boolean().required(),
  }),
});
