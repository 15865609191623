// A text field with a £ sign as a prefix
import { Typography } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';

import { StyledPriceFieldContainer, StyledPrefixWrapper } from './index.styled';
import { PriceTextFieldProps } from './index.types';

const PriceTextField = ({
  name,
  label,
  placeholder,
  onBlur,
}: PriceTextFieldProps) => (
  <StyledPriceFieldContainer>
    <FormikTextField
      name={name}
      type="number"
      label={label}
      placeholder={placeholder}
      startIcon={
        <StyledPrefixWrapper>
          <Typography>£</Typography>
        </StyledPrefixWrapper>
      }
      onBlur={onBlur}
      attributes={{ 'data-recite-skip': 'true' }}
    />
  </StyledPriceFieldContainer>
);

export default PriceTextField;
