export enum APPLY_FOR_METER {
  OVERVIEW = 'Overview',
  YOUR_HOME = 'Your home',
  BILLING_PLAN = 'Billing plan',
  WATER_SUPPLY = 'Water supply',
  STOP = 'Stop',
  APPOINTMENT = 'Appointment',
  REVIEW = 'Review',
  SUCCESS = 'Success',
}
