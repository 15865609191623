import Link from 'next/link';
import { Container, Stack, Typography } from '@krakentech/coral';

import { useHasActiveWaterMeter } from '@/hooks/usage/useHasActiveWaterMeter';

import { useNavigationLinks } from '../useNavigationLinks';

import { StyledTabContainerOuter, StyledTabLink } from './index.styled';

const DesktopNav = () => {
  const { activeTab, routes } = useNavigationLinks();

  const {
    isLoading: isLoadingHasActiveWaterMeter,
    isError: isErrorHasActiveWaterMeter,
    data: activeWaterMeterCount,
  } = useHasActiveWaterMeter();

  if (isLoadingHasActiveWaterMeter || isErrorHasActiveWaterMeter) {
    return null;
  }

  const displayUsageLink = activeWaterMeterCount === 1;

  return (
    <StyledTabContainerOuter>
      <Container
        component="section"
        marginBottom="lg"
        marginX="auto"
        maxWidth="lg"
        paddingX="lg"
        paddingY="md"
      >
        <Stack alignItems="center" fullHeight gap="lg" lg={{ gap: 'xl' }}>
          <Link href={routes.overview} passHref legacyBehavior>
            <StyledTabLink active={activeTab === 'overview'}>
              <Typography variant="h2" component="p">
                Overview
              </Typography>
            </StyledTabLink>
          </Link>
          <Link href={routes.bills} passHref legacyBehavior>
            <StyledTabLink active={activeTab === 'bills'}>
              <Typography variant="h2" component="p">
                Bills
              </Typography>
            </StyledTabLink>
          </Link>

          {displayUsageLink && (
            <Link href={routes.usage} passHref legacyBehavior>
              <StyledTabLink active={activeTab === 'usage'}>
                <Typography variant="h2" component="p">
                  Usage
                </Typography>
              </StyledTabLink>
            </Link>
          )}

          <Link href={routes.settings} passHref legacyBehavior>
            <StyledTabLink active={activeTab === 'settings'}>
              <Typography variant="h2" component="p">
                Settings
              </Typography>
            </StyledTabLink>
          </Link>
        </Stack>
      </Container>
    </StyledTabContainerOuter>
  );
};

export default DesktopNav;
