import { FormikTextField } from '@krakentech/coral-formik';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import { postcodeRegex, postcodeValidationMessage } from '@/consts/validation';

async function validatePostcode(value: string): Promise<string | void> {
  try {
    await Yup.string()
      .matches(postcodeRegex, postcodeValidationMessage)
      .required(postcodeValidationMessage)
      .validate(value);
  } catch (error: unknown) {
    return (error as Error).message;
  }
}

type PostcodeInputProps = {
  onPostcodeChanged: (postcode: string) => void;
};

export function PostcodeInput({ onPostcodeChanged }: PostcodeInputProps) {
  const { setFieldValue } = useFormikContext<{ postcode: string }>();

  return (
    <FormikTextField
      name="postcode"
      label="Enter postcode"
      onChange={async (event) => {
        const { value } = event.target;
        const transformedPostcode = value.toUpperCase().trim();
        await setFieldValue('postcode', transformedPostcode);

        onPostcodeChanged(transformedPostcode);
      }}
      validate={validatePostcode}
      attributes={{ 'data-recite-skip': 'true' }}
    />
  );
}
