export enum FeatureNames {
  OnlinePayment = 'ONLINE_PAYMENT',
  DirectDebit = 'DIRECT_DEBIT',
  ChangeBankDetails = 'CHANGE_BANK_DETAILS',
  ChangePaymentSchedule = 'CHANGE_PAYMENT_SCHEDULE',
  PSRRenewal = 'PSR_RENEWAL',
  HomeMove = 'HOME_MOVES',
  RegisterForOnlineAccount = 'REGISTER_FOR_ONLINE_ACCOUNT',
  ForgottenEmail = 'FORGOTTEN_EMAIL',
  CreateNewAccount = 'CREATE_NEW_ACCOUNT',
  AdobeAnalytics = 'ADOBE_ANALYTICS',
  GoogleAnalytics = 'GOOGLE_ANALYTICS',
  RequestPrintedBill = 'REQUEST_PRINTED_BILL',
  NectarPhaseOne = 'NECTAR_PHASE_ONE',
  ApplyForMeter = 'APPLY_FOR_METER',
}
