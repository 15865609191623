import { Grid } from '@krakentech/coral';

import { useHasActiveWaterMeter } from '@/hooks/usage/useHasActiveWaterMeter';
import {
  CogActiveIcon,
  CogIcon,
  HouseActiveIcon,
  HouseIcon,
  PoundSignActiveIcon,
  PoundSignIcon,
  WaterMeterActiveIcon,
  WaterMeterIcon,
} from '@/public/images';

import MobileNavLink from '../MobileNavLink';
import { useNavigationLinks } from '../useNavigationLinks';

import { StyledMobileTabContainer } from './index.styled';

const MobileNav = () => {
  const { activeTab, routes } = useNavigationLinks();

  const {
    isLoading: isLoadingHasActiveWaterMeter,
    isError: isErrorHasActiveWaterMeter,
    data: activeWaterMeterCount,
  } = useHasActiveWaterMeter();

  if (isLoadingHasActiveWaterMeter || isErrorHasActiveWaterMeter) {
    return null;
  }

  const displayUsageLink = activeWaterMeterCount === 1;

  return (
    <StyledMobileTabContainer>
      <Grid
        gap={0}
        templateColumns={`repeat(${displayUsageLink ? '4' : '3'}, 1fr)`}
      >
        <MobileNavLink
          href={routes.overview}
          label="Overview"
          isActive={activeTab === 'overview'}
          ActiveIcon={<HouseActiveIcon />}
          inactiveIcon={HouseIcon}
        />

        <MobileNavLink
          href={routes.bills}
          label="Bills"
          isActive={activeTab === 'bills'}
          ActiveIcon={<PoundSignActiveIcon />}
          inactiveIcon={PoundSignIcon}
          iconWidth={15}
        />

        {displayUsageLink && (
          <MobileNavLink
            href={routes.usage}
            label="Usage"
            isActive={activeTab === 'usage'}
            ActiveIcon={<WaterMeterActiveIcon />}
            inactiveIcon={WaterMeterIcon}
          />
        )}

        <MobileNavLink
          href={routes.settings}
          label="Settings"
          isActive={activeTab === 'settings'}
          ActiveIcon={<CogActiveIcon />}
          inactiveIcon={CogIcon}
        />
      </Grid>
    </StyledMobileTabContainer>
  );
};

export default MobileNav;
