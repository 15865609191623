import { Stack } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Alert, MutedText, FormSubmitButton } from '@/components';

type MobileNumberFormProps = {
  initialMobileNumber?: string;
  isLoading?: boolean;
  errorMessageOnSubmit?: string;
  onSubmit: (value: { mobileNumber: string }) => void;
};

const MobileNumberForm = ({
  initialMobileNumber = '',
  isLoading = false,
  errorMessageOnSubmit = '',
  onSubmit,
}: MobileNumberFormProps) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={{
      mobileNumber: initialMobileNumber,
    }}
    validationSchema={Yup.object().shape({
      mobileNumber: Yup.string()
        .required('Please enter a mobile number')
        .phone('GB', 'Please enter a valid mobile number'),
    })}
  >
    {({ values: { mobileNumber }, dirty }) => (
      <Form>
        <Stack direction="vertical" gap="lg">
          <Stack direction="vertical" gap="xs">
            <FormikTextField
              name="mobileNumber"
              label="Mobile number"
              attributes={{ 'data-recite-skip': 'true' }}
            />
            <MutedText>For example: +447123456789</MutedText>
          </Stack>
          {errorMessageOnSubmit && (
            <Alert severity="error">{errorMessageOnSubmit}</Alert>
          )}
          <FormSubmitButton
            loading={isLoading}
            disabled={!dirty && !mobileNumber}
          >
            Continue
          </FormSubmitButton>
        </Stack>
      </Form>
    )}
  </Formik>
);

export default MobileNumberForm;
