import { Grid } from '@krakentech/coral';
import { IconFileList } from '@krakentech/icons';

import { ActionCard } from '@/components';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { useLatestBill } from '@/hooks/billsAndPayments/useLatestBill';
import { INTERNAL_PATHS } from '@/utils/urls';

const LatestBillCard = () => {
  const { accountNumber } = useAccountNumberContext();

  const { isLoading, isError, data } = useLatestBill({ accountNumber });

  if (!data || isError) {
    return null;
  }

  const latestBill = data.node;

  return (
    <Grid.Item>
      <ActionCard
        title="View latest bill"
        titleComponent="p"
        icon={<IconFileList size={32} />}
        href={INTERNAL_PATHS.SINGLE_BILL_DOWNLOAD.createPath({
          id: latestBill.id,
          billType: latestBill.billType,
        })}
        openInNewTab
        isLoading={isLoading}
        isError={isError}
      />
    </Grid.Item>
  );
};

export default LatestBillCard;
