import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { hasActiveSocialAgreementSchema } from '@/hooks/accounts/validations';

import HasActiveSocialAgreement from './HasActiveSocialAgreement.graphql';

type SocialAgreement = {
  account: {
    hasActiveSocialAgreement: boolean;
  };
};

export const useHasActiveSocialAgreement = () => {
  const { isAuthenticated } = useAuth();

  const { accountNumber } = useAccountNumberContext();

  return useKrakenQuery({
    queryKey: ['hasActiveSocialAgreement'],
    query: HasActiveSocialAgreement,
    select: (data: SocialAgreement) => data.account.hasActiveSocialAgreement,
    validateFn(response) {
      return hasActiveSocialAgreementSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
    variables: { accountNumber },
  });
};
