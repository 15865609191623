import {
  FoundAddress,
  FoundBillingAddress,
  isFoundBillingAddress,
} from '@/pages/move-home/types';

export type AddressOption = {
  value: string | number;
  label: string;
  propertyId?: string;
  activeMeterCount?: number;
};
export type AddressOptions = Array<AddressOption>;

export const getAddressList = (
  addresses: FoundAddress[] | FoundBillingAddress[] | undefined
): AddressOptions | undefined => {
  if (!addresses) {
    return undefined;
  }

  const addressDataValues: AddressOptions = addresses
    .map((address: FoundAddress | FoundBillingAddress) => {
      if (!address.node) return;

      if (isFoundBillingAddress(address)) {
        return {
          value: address.node,
          label: address.node,
        };
      } else {
        return {
          value: address.node.address,
          label: address.node.address,
          propertyId: address.node.propertyId ?? undefined,
          activeMeterCount: address.node.activeMeterCount ?? undefined,
          isAssessed: address.node.isAssessed ?? undefined,
        };
      }
    })
    .filter((address) => !!address);

  return addressDataValues.sort((a, b) => {
    const numA = parseInt(a.value.toString(), 10);
    const numB = parseInt(b.value.toString(), 10);

    const addressA = isNaN(numA) ? 0 : numA;
    const addressB = isNaN(numB) ? 0 : numB;

    return addressA - addressB;
  });
};
