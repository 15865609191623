import { useEffect, useState } from 'react';
import { Card, Link, Stack, Typography } from '@krakentech/coral';
import { useFormikContext } from 'formik';

import { Alert, Select } from '@/components';
import {
  AddressOption,
  AddressOptions,
  getAddressList,
} from '@/components/AddressFinder/getAddressList';
import { PostcodeSearch } from '@/components/AddressFinder/PostcodeSearch';

import { AddressFinderProps } from './index.types';
import { useFetchAddressData } from './useFetchAddressData';

const AddressFinder = ({
  showMandatoryAddressCopy,
  defaultSelectedAddress,
  handleAddressChange,
  query,
  title = 'Enter your new address',
  isPropertyAssessed = false,
}: AddressFinderProps) => {
  const {
    values: { postcode: postcodeValue },
    setFieldValue,
    initialValues,
  } = useFormikContext<{
    postcode: string;
    address: AddressOption;
  }>();

  const [addressOptions, setAddressOptions] = useState<
    AddressOptions | undefined
  >(initialValues.address ? [initialValues.address] : undefined);
  const noOptionsAvailable =
    postcodeValue !== '' && addressOptions?.length === 0;

  useEffect(() => {
    const postcodeIsInSavedAddress = defaultSelectedAddress?.value
      .toString()
      .includes(postcodeValue);
    if (!postcodeIsInSavedAddress) {
      setAddressOptions(undefined);
    }
  }, [defaultSelectedAddress, postcodeValue]);

  const { fetchingAddressData, fetchFailed, fetchedData, fetchData } =
    useFetchAddressData(query, postcodeValue);

  useEffect(() => {
    if (fetchedData) {
      setAddressOptions(getAddressList(fetchedData));
    }
  }, [fetchedData]);

  return (
    <Card>
      <Stack direction="vertical" gap="smMd">
        <Typography variant="h2">{title}</Typography>
        {showMandatoryAddressCopy && (
          <Typography>
            Please provide your future address so we can forward important
            information, such as bills or credits.
          </Typography>
        )}
        <PostcodeSearch
          onChange={async () => {
            setAddressOptions(undefined);
            await setFieldValue('address', undefined);
          }}
          onSearch={async () => {
            setAddressOptions(undefined);
            await fetchData();
          }}
          loading={fetchingAddressData}
        />
        {!(fetchingAddressData || noOptionsAvailable) &&
          // This can happen if we fetched data previously, and a toggle card was turned on and off.
          postcodeValue !== '' &&
          addressOptions && (
            <Select
              name="address"
              label="Select an address"
              values={addressOptions}
              onChange={handleAddressChange}
            />
          )}
        {(noOptionsAvailable || fetchFailed) && (
          <Alert severity="error">
            <Typography>
              No addresses found. Make sure you&apos;ve entered the postcode
              correctly & try again
            </Typography>
          </Alert>
        )}
        {isPropertyAssessed && (
          <Alert severity="error">
            We&apos;re unable to complete your move in online because we need a
            bit more information about your property and household. Don&apos;t
            worry, our team can help! Please{' '}
            <Link href={process.env.NEXT_PUBLIC_HELP_LINK} target="_blank">
              contact us
            </Link>
            , and we&apos;ll guide you through the process.
          </Alert>
        )}
      </Stack>
    </Card>
  );
};

export default AddressFinder;
