import { useEffect, useMemo } from 'react';
import {
  Card,
  Stack,
  Skeleton,
  useCoralBreakpoints,
  Grid,
} from '@krakentech/coral';

import { PaymentInfoCard } from '@/components';
import { PAYMENT_FREQUENCY } from '@/consts/paymentFrequency';
import { useCurrentActivePaymentSchedule } from '@/hooks/billsAndPayments/useCurrentActivePaymentSchedule';
import { useHasCurrentDirectDebitInstruction } from '@/hooks/billsAndPayments/useHasCurrentDirectDebitInstruction';
import { usePaymentForecast } from '@/hooks/billsAndPayments/usePaymentForecast';
import { useHasActiveWaterMeter } from '@/hooks/usage/useHasActiveWaterMeter';
import { isValidDirectDebitInstruction } from '@/utils/directDebit';
import { formatCurrency } from '@/utils/formatters/currency';
import { formatFullDateShortMonth } from '@/utils/formatters/date';

import { H2Skeleton, Body1Skeleton } from '../../Skeletons';

const NextPaymentCard = ({
  setNumberOfDesktopColumns,
}: {
  setNumberOfDesktopColumns: (newNumberOfDesktopColumns: number) => void;
}) => {
  const {
    isLoading: isLoadingCurrentSchedule,
    isError: isErrorCurrentSchedule,
    data: currentSchedule,
  } = useCurrentActivePaymentSchedule();
  const {
    isLoading: isLoadingNextPayment,
    isError: isErrorNextPayment,
    data: nextPaymentData,
  } = usePaymentForecast({
    first: 1,
  });
  const {
    isLoading: isLoadingHasCurrentDirectDebitInstruction,
    error: hasCurrentDirectDebitInstructionError,
    data: directDebitInstruction,
  } = useHasCurrentDirectDebitInstruction();
  const { data: hasActiveWaterMeter } = useHasActiveWaterMeter();

  const displayLoadingState =
    isLoadingCurrentSchedule ||
    isLoadingNextPayment ||
    isLoadingHasCurrentDirectDebitInstruction;

  const hasFixedPaymentSchedule =
    currentSchedule?.node && !currentSchedule?.node.isVariablePaymentAmount;

  const hasNextPaymentData = !!nextPaymentData?.length;

  const hideNextPaymentCard = useMemo(
    () =>
      !directDebitInstruction?.node &&
      hasFixedPaymentSchedule &&
      !hasActiveWaterMeter,
    [directDebitInstruction, hasFixedPaymentSchedule, hasActiveWaterMeter]
  );

  useEffect(() => {
    if (!hideNextPaymentCard && hasNextPaymentData) {
      setNumberOfDesktopColumns(2);
    } else {
      setNumberOfDesktopColumns(1);
    }
  }, [hideNextPaymentCard, hasNextPaymentData, setNumberOfDesktopColumns]);

  if (!hasFixedPaymentSchedule || hideNextPaymentCard || !hasNextPaymentData) {
    return null;
  }

  if (displayLoadingState) {
    return <LoadingState />;
  }

  if (
    isErrorCurrentSchedule ||
    isErrorNextPayment ||
    hasCurrentDirectDebitInstructionError
  ) {
    return null;
  }

  const nextPayment = nextPaymentData[0].node;

  const {
    node: { paymentFrequency, paymentFrequencyMultiplier },
  } = currentSchedule;

  const hasValidDirectDebitInstruction = isValidDirectDebitInstruction(
    directDebitInstruction?.node
  );

  const nextPaymentCardHeader =
    paymentFrequency === PAYMENT_FREQUENCY.MONTHLY &&
    paymentFrequencyMultiplier === '1'
      ? 'Next monthly payment'
      : 'Next payment';

  const nextPaymentCardFooter = hasValidDirectDebitInstruction
    ? `This will be taken on ${formatFullDateShortMonth(nextPayment.date)}`
    : `Please pay on or before ${formatFullDateShortMonth(nextPayment.date)}`;

  return (
    <PaymentInfoCard
      header={nextPaymentCardHeader}
      amount={formatCurrency(nextPayment.amount)}
      explanation={nextPaymentCardFooter}
      dataAttributes={{ 'data-testid': 'nextPaymentCard' }}
      isNextMonthlyPaymentCard
    />
  );
};

const LoadingState = () => {
  const { isMaxMd } = useCoralBreakpoints();

  return (
    <Grid.Item>
      <Card padding="large">
        <Stack fullHeight direction="vertical" alignItems="center" gap="smMd">
          <H2Skeleton width={isMaxMd ? 200 : 250} />

          <Skeleton
            variant="rounded"
            width={isMaxMd ? undefined : 250}
            height={60}
          />

          <Body1Skeleton width={isMaxMd ? 200 : 250} />
        </Stack>
      </Card>
    </Grid.Item>
  );
};

export default NextPaymentCard;
