import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import ActiveWatersureAgreement from '@/hooks/accounts/useHasActiveWatersureAgreement/HasActiveWatersureAgreement.graphql';
import { hasActiveWatersureAgreementSchema } from '@/hooks/accounts/validations';

type ActiveWatersureAgreement = {
  account: {
    hasActiveWatersureAgreement: boolean;
  };
};

export const useHasActiveWatersureAgreement = () => {
  const { isAuthenticated } = useAuth();

  const { accountNumber } = useAccountNumberContext();

  return useKrakenQuery({
    queryKey: ['hasActiveWatersureAgreement'],
    query: ActiveWatersureAgreement,
    select: (data: ActiveWatersureAgreement) =>
      data.account.hasActiveWatersureAgreement,
    validateFn(response) {
      return hasActiveWatersureAgreementSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
    variables: { accountNumber },
  });
};
