import { ChangeEvent, RefObject, useRef } from 'react';
import { ErrorMessage, Grid, Stack, Typography } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';
import { FieldArray, useFormikContext } from 'formik';

import { MutedText } from '@/components';
import { HideArrowsFromNumberInput } from '@/styles/Overrides.styled';
import { SeparatedDate } from '@/types/date';
import { isFieldEmpty } from '@/utils/validators/general';

import { FormValues } from './index.types';

const DateField = ({
  title,
  subtitle,
  fieldArrayName,
  subFieldArrayName,
  helperText = 'For example: 01 01 1970',
  disabled = false,
}: {
  title: string;
  subtitle?: string;
  fieldArrayName: string;
  subFieldArrayName?: string;
  helperText?: string;
  disabled?: boolean;
}) => {
  const { setFieldValue, errors, touched, initialValues } =
    useFormikContext<FormValues<typeof fieldArrayName>>();

  const monthInputRef = useRef<HTMLInputElement>(null);
  const yearInputRef = useRef<HTMLInputElement>(null);

  const fieldName = subFieldArrayName
    ? `${fieldArrayName}.${subFieldArrayName}`
    : `${fieldArrayName}`;

  const dayField = `${fieldName}.day`;
  const monthField = `${fieldName}.month`;
  const yearField = `${fieldName}.year`;

  const makeFieldTwoDigitsOnBlur = (
    { target }: ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    if (target.value.length === 1) {
      setFieldValue(field, 0 + target.value);
    }
  };

  const handleInputFocusOnTwoDigits = (
    { target }: ChangeEvent<HTMLInputElement>,
    targetRef: RefObject<HTMLInputElement>
  ) => {
    if (target.value.length === 2) {
      targetRef.current && targetRef.current.focus();
    }
  };

  const doesDateInitiallyExist = () => {
    if (subFieldArrayName) {
      const nestedInitialValues = (
        initialValues[fieldArrayName] as {
          [key: string]: SeparatedDate;
        }
      )?.[subFieldArrayName];

      return (
        !isFieldEmpty(nestedInitialValues?.day) &&
        !isFieldEmpty(nestedInitialValues?.month) &&
        !isFieldEmpty(nestedInitialValues?.year)
      );
    }

    const baseInitialValues = initialValues[fieldArrayName];

    return (
      !isFieldEmpty(baseInitialValues?.day as string) &&
      !isFieldEmpty(baseInitialValues?.month as string) &&
      !isFieldEmpty(baseInitialValues?.year as string)
    );
  };

  const areFieldsTouched = () => {
    if (subFieldArrayName) {
      const nestedTouched = (
        touched[fieldArrayName] as { [key: string]: SeparatedDate }
      )?.[subFieldArrayName];

      return nestedTouched?.day && nestedTouched?.month && nestedTouched?.year;
    }

    const baseTouched = touched[fieldArrayName];
    return baseTouched?.day && baseTouched?.month && baseTouched?.year;
  };

  const getFieldError = () => {
    return subFieldArrayName
      ? (errors[fieldArrayName] as { [key: string]: SeparatedDate })?.[
          subFieldArrayName
        ]
      : errors[fieldArrayName];
  };

  return (
    <Stack direction="vertical" gap="sm">
      <Stack direction="vertical" gap="xs">
        <Typography variant="h3">{title}</Typography>
        {subtitle && <MutedText variant="body1">{subtitle}</MutedText>}
      </Stack>

      <FieldArray
        name={fieldArrayName}
        render={() => (
          <>
            <Stack direction="vertical" gap="xs">
              <Grid
                gap={'sm'}
                templateAreas={`
                                    "day"
                                    "month"
                                    "year"
                                `}
                sm={{
                  gap: 'md',
                  templateAreas: `
                                        "day month year"
                                    `,
                }}
              >
                <Grid.Item area="day">
                  <HideArrowsFromNumberInput>
                    <FormikTextField
                      name={dayField}
                      label="Day"
                      inputProps={{
                        inputMode: 'numeric',
                      }}
                      onBlur={(e) => makeFieldTwoDigitsOnBlur(e, dayField)}
                      onChange={(e) =>
                        handleInputFocusOnTwoDigits(e, monthInputRef)
                      }
                      type="number"
                      disabled={disabled}
                      attributes={{ 'data-recite-skip': 'true' }}
                    />
                  </HideArrowsFromNumberInput>
                </Grid.Item>
                <Grid.Item area="month">
                  <HideArrowsFromNumberInput>
                    <FormikTextField
                      name={monthField}
                      label="Month"
                      inputProps={{
                        inputMode: 'numeric',
                      }}
                      onBlur={(e) => makeFieldTwoDigitsOnBlur(e, monthField)}
                      ref={monthInputRef}
                      onChange={(e) =>
                        handleInputFocusOnTwoDigits(e, yearInputRef)
                      }
                      type="number"
                      disabled={disabled}
                      attributes={{ 'data-recite-skip': 'true' }}
                    />
                  </HideArrowsFromNumberInput>
                </Grid.Item>
                <Grid.Item area="year">
                  <HideArrowsFromNumberInput>
                    <FormikTextField
                      name={yearField}
                      label="Year"
                      inputProps={{
                        inputMode: 'numeric',
                      }}
                      ref={yearInputRef}
                      type="number"
                      disabled={disabled}
                      attributes={{ 'data-recite-skip': 'true' }}
                    />
                  </HideArrowsFromNumberInput>
                </Grid.Item>
              </Grid>
              <MutedText variant="caption">{helperText}</MutedText>
              {(doesDateInitiallyExist() || areFieldsTouched()) &&
                getFieldError() && (
                  <ErrorMessage
                    // TODO: fix typing below
                    // @ts-expect-error fix typing below
                    message={getFieldError()}
                  />
                )}
            </Stack>
          </>
        )}
      />
    </Stack>
  );
};

export default DateField;
