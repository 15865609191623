import { useEffect } from 'react';
import { Container, Stack, Error } from '@krakentech/coral';

import FormContainer from '../forms/FormContainer';

const PageError = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container
      component="section"
      marginBottom="lg"
      marginX="auto"
      maxWidth="lg"
      padding="md"
      paddingY="xxl"
    >
      <FormContainer>
        <Stack justifyContent="center">
          <Error message="It looks like something is broken on our end, but this should be sorted soon. If you've seen this a few times please let us know by getting in contact." />
        </Stack>
      </FormContainer>
    </Container>
  );
};

export default PageError;
