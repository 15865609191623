import { Stack, Error } from '@krakentech/coral';

import FormContainer from '../forms/FormContainer';

const NotFoundError = () => (
  <FormContainer>
    <Stack justifyContent="center">
      <Error
        heading="404"
        subheading="Page not found"
        message="The link you clicked may be broken or the page may have been removed."
      />
    </Stack>
  </FormContainer>
);

export default NotFoundError;
