import { Typography } from '@krakentech/coral';
import { addDays, isFuture } from 'date-fns';

import { Alert } from '@/components';
import { PAYMENT_FREQUENCY } from '@/consts/paymentFrequency';
import { useCurrentActivePaymentSchedule } from '@/hooks/billsAndPayments/useCurrentActivePaymentSchedule';
import { useUpcomingPaymentSchedule } from '@/hooks/billsAndPayments/useUpcomingPaymentSchedule';
import { useSyncFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';
import { formatCurrency } from '@/utils/formatters/currency';
import {
  formatFullDateShortMonth,
  makeDateKrakenFriendly,
} from '@/utils/formatters/date';
import { cardinalNumberToOrdinalNumber } from '@/utils/formatters/number';
import { getPaymentFrequency } from '@/utils/paymentSchedule';

const UpcomingPaymentSchedule = () => {
  const {
    isLoading: isLoadingCurrentPaymentSchedule,
    error: isErrorCurrentPaymentSchedule,
    data: currentPaymentSchedule,
  } = useCurrentActivePaymentSchedule();

  const validToDate = currentPaymentSchedule?.node?.validTo;

  // Use `makeDateKrakenFriendly` to ensure `activeOnDate` is in the correct format
  const activeOnDate =
    validToDate && !isNaN(new Date(validToDate).getTime())
      ? makeDateKrakenFriendly(addDays(new Date(validToDate), 1))
      : undefined;

  const {
    isLoading: isLoadingUpcomingPaymentSchedule,
    error: isErrorUpcomingPaymentSchedule,
    data: upcomingPaymentSchedule,
  } = useUpcomingPaymentSchedule({
    activeOnDate,
  });

  const changePaymentScheduleEnabled = useSyncFeatureFlag(
    FeatureNames.ChangePaymentSchedule
  );

  if (isLoadingCurrentPaymentSchedule || isLoadingUpcomingPaymentSchedule) {
    return null;
  }

  if (isErrorCurrentPaymentSchedule || isErrorUpcomingPaymentSchedule) {
    return null;
  }

  // Don't show anything if the user either doens't have an upcoming payment schedule or if their upcoming payment schedule
  if (!upcomingPaymentSchedule?.node) {
    return null;
  }

  const {
    paymentAmount,
    validFrom,
    paymentFrequency,
    paymentFrequencyMultiplier,
    isVariablePaymentAmount,
    paymentDay,
  } = upcomingPaymentSchedule.node;

  // Don't show anything if the "upcoming payment schedule" has already started. This can happen for customers whose payment schedule has an end date, but there's no payment schedule afterwards
  if (!isFuture(validFrom)) {
    return null;
  }

  const originalPaymentScheduleCopy = (
    <Typography>
      After <strong>{formatFullDateShortMonth(validFrom)}</strong>, you&apos;ll
      be paying{' '}
      {isVariablePaymentAmount ? (
        <strong>when you get your bill</strong>
      ) : (
        <strong>
          {formatCurrency(paymentAmount)} every{' '}
          {getPaymentFrequency(paymentFrequency, paymentFrequencyMultiplier)}
          {paymentFrequency === PAYMENT_FREQUENCY.MONTHLY &&
            ` on the ${cardinalNumberToOrdinalNumber(paymentDay)}`}
        </strong>
      )}
    </Typography>
  );

  const updatedPaymentScheduleCopy = (
    <Typography>
      We&apos;re processing your payment change. Once completed, you&apos;ll{' '}
      {isVariablePaymentAmount ? (
        <>switch to a variable plan and pay when you get your bill.</>
      ) : (
        <>
          start paying {formatCurrency(paymentAmount)} every{' '}
          {getPaymentFrequency(paymentFrequency, paymentFrequencyMultiplier)}
          {paymentFrequency === PAYMENT_FREQUENCY.MONTHLY &&
            ` on the ${cardinalNumberToOrdinalNumber(paymentDay)}.`}
        </>
      )}
    </Typography>
  );

  return (
    <Alert severity="info">
      {changePaymentScheduleEnabled
        ? updatedPaymentScheduleCopy
        : originalPaymentScheduleCopy}
    </Alert>
  );
};

export default UpcomingPaymentSchedule;
