import { Grid } from '@krakentech/coral';
import { IconGBP } from '@krakentech/icons';

import { ActionCard } from '@/components';
import { useHasActiveSocialAgreement } from '@/hooks/accounts/useHasActiveSocialAgreement';
import { useCurrentActivePaymentSchedule } from '@/hooks/billsAndPayments/useCurrentActivePaymentSchedule';
import { useHasCurrentDirectDebitInstruction } from '@/hooks/billsAndPayments/useHasCurrentDirectDebitInstruction';
import { INTERNAL_PATHS } from '@/utils/urls';

const DirectDebitActionCard = () => {
  const {
    isLoading: isDirectDebitInstructionLoading,
    isError: isDirectDebitInstructionError,
    data: hasDirectDebitInstruction,
  } = useHasCurrentDirectDebitInstruction();

  const { data: paymentSchedule } = useCurrentActivePaymentSchedule();
  const { data: hasActiveSocialAgreement } = useHasActiveSocialAgreement();

  const cardTitle = hasDirectDebitInstruction
    ? 'Manage payments'
    : 'Set up a Direct Debit';

  const hasDDIButNoActivePaymentSchedule =
    hasDirectDebitInstruction && typeof paymentSchedule === 'undefined';

  const hasDDButNoDDPaymentScheduleType =
    hasDirectDebitInstruction &&
    paymentSchedule?.node?.scheduleType !== 'DIRECT_DEBIT';

  const getActionCardHref = () => {
    if (hasDirectDebitInstruction) {
      return INTERNAL_PATHS.MANAGE_PAYMENTS.path;
    } else {
      return INTERNAL_PATHS.SET_UP_DIRECT_DEBIT[0].path;
    }
  };

  if (
    hasDDIButNoActivePaymentSchedule ||
    hasDDButNoDDPaymentScheduleType ||
    (hasActiveSocialAgreement && !hasDirectDebitInstruction)
  ) {
    return null;
  }

  return (
    <Grid.Item>
      <ActionCard
        href={getActionCardHref()}
        title={cardTitle}
        titleComponent="p"
        icon={<IconGBP size={32} />}
        isLoading={isDirectDebitInstructionLoading}
        isError={isDirectDebitInstructionError}
      />
    </Grid.Item>
  );
};

export default DirectDebitActionCard;
