import {
  Stack,
  Tooltip,
  Typography,
  useCoralBreakpoints,
} from '@krakentech/coral';

import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { useFullNameAndSupplyAddress } from '@/hooks/accounts/useFullNameAndSupplyAddress';

import { Body1Skeleton, H1Skeleton } from '../../Skeletons';

import { StyledTooltipToggle } from './index.styled';

const DashboardGreeting = () => {
  const { isMaxMd } = useCoralBreakpoints();

  const { accountNumber } = useAccountNumberContext();

  const { data, isError } = useFullNameAndSupplyAddress();

  if (data) {
    const {
      viewer: { title, fullName },
      account: { properties },
    } = data;

    const hasMovedOut = !properties.length;

    /* TODO: handle those with multiple addresses (not necessary for this cohort) */
    const isMetered = properties[0]?.activeWaterMeters?.length;

    return (
      <Stack direction="vertical" gap="xxs" md={{ gap: 'xs' }}>
        <Typography variant="h1" attributes={{ 'data-recite-skip': 'true' }}>
          Hello {title} {fullName}
        </Typography>

        <Stack
          gap="xxs"
          direction="vertical"
          alignItems="flex-start"
          md={{
            direction: 'horizontal',
            gap: 'sm',
          }}
        >
          {/* TODO: handle those with multiple addresses (not necessary for this cohort) */}
          {properties[0]?.address && (
            <>
              <Typography>{properties[0]?.address}</Typography>
              {!isMaxMd && <Typography>|</Typography>}
            </>
          )}

          <Stack gap="xs" md={{ gap: 'sm' }}>
            <Tooltip title="Your account number" theme="dark" zIndex={1}>
              <Typography>
                <StyledTooltipToggle>{accountNumber}</StyledTooltipToggle>
              </Typography>
            </Tooltip>
            {!hasMovedOut && (
              <>
                <Typography>|</Typography>
                <Tooltip
                  theme="dark"
                  title={
                    isMetered
                      ? 'Your property has a meter installed which tracks your usage'
                      : 'Your property does not currently have a meter installed'
                  }
                  zIndex={1}
                >
                  <Typography>
                    <StyledTooltipToggle>
                      {isMetered ? 'Metered' : 'Unmetered'}
                    </StyledTooltipToggle>
                  </Typography>
                </Tooltip>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  }

  if (isError) {
    return null;
  }

  return (
    <Stack direction="vertical" gap="sm">
      <H1Skeleton />
      <Body1Skeleton width={isMaxMd ? undefined : 750} />
    </Stack>
  );
};

export default DashboardGreeting;
