import { useEffect, useMemo, useState } from 'react';
import { Link, Stack, Typography, Visibility } from '@krakentech/coral';
import { IconFile } from '@krakentech/icons';

import OrderPrintedBillDialog from '@/components/dialogs/OrderPrintedBillDialog';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { useBills } from '@/hooks/billsAndPayments/useBills';
import { useRequestPrintedBill } from '@/hooks/billsAndPayments/useRequestPrintedBill';
import { useFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { usePagination } from '@/hooks/utils/usePagination';
import { FeatureNames } from '@/types/features';
import { formatCurrency } from '@/utils/formatters/currency';
import { formatFullDateShortMonth } from '@/utils/formatters/date';
import { INTERNAL_PATHS } from '@/utils/urls';

import NoDataMessage from '../../NoDataMessage';
import PageError from '../../PageError';
import Paginator from '../../Paginator';
import { Body2Skeleton } from '../../Skeletons';
import Table from '../../Table';

type BillDetails = { billType: string; id: string };

const BillsList = ({
  showSuccess,
  showError,
}: {
  showSuccess: () => void;
  showError: () => void;
}) => {
  const { accountNumber } = useAccountNumberContext();
  const [billDetails, setBillDetails] = useState<BillDetails | null>(null);

  const {
    data,
    isLoading,
    isError: isBillsError,
    hasNextPage,
    fetchNextPage,
  } = useBills({
    accountNumber,
  });

  const requestPrintedBillEnabled = useFeatureFlag(
    FeatureNames.RequestPrintedBill
  );

  const {
    mutate: mutateRequestPrintedBill,
    isError: isRequestPrintedBillError,
    isSuccess: isRequestPrintedBillSuccess,
    isLoading: isRequestPrintedBillLoading,
  } = useRequestPrintedBill();

  const { paginatedData, numberOfDisplayedItems, totalNumberOfItems } =
    usePagination(data);

  useEffect(() => {
    if (isRequestPrintedBillSuccess) {
      showSuccess();
      setBillDetails(null);
    }
    if (isRequestPrintedBillError) {
      showError();
      setBillDetails(null);
    }
  }, [
    isRequestPrintedBillSuccess,
    isRequestPrintedBillError,
    showSuccess,
    showError,
    setBillDetails,
  ]);

  const desktopColumns = useMemo(
    () => [
      ...(requestPrintedBillEnabled
        ? []
        : [
            {
              name: 'Date issued',
              width: 10,
            },
          ]),
      {
        name: 'Billing period',
        width: 50,
      },
      {
        name: 'Amount',
        width: 10,
      },
      {
        name: '',
        width: 30,
      },
      ...(requestPrintedBillEnabled
        ? [
            {
              name: '',
              width: 10,
            },
          ]
        : []),
    ],
    [requestPrintedBillEnabled]
  );

  if (isLoading) {
    return <LoadingState />;
  }

  if (isBillsError) {
    return <PageError />;
  }

  const chargeAmount = (grossTotal: number) => formatCurrency(grossTotal);

  const billingPeriod = (fromDate: string, toDate: string) =>
    `${formatFullDateShortMonth(
      new Date(fromDate)
    )} - ${formatFullDateShortMonth(new Date(toDate))}`;

  const handleOrderPrintedCopy = () => {
    if (!billDetails) return;
    mutateRequestPrintedBill({
      input: {
        billingDocumentId: billDetails.id,
        accountNumber,
      },
    });
  };

  return (
    <>
      {paginatedData?.length ? (
        <>
          <Visibility display="none" md={{ display: 'block' }}>
            <Table
              columns={desktopColumns}
              data={paginatedData.map(
                ({
                  node: {
                    id,
                    issuedDate,
                    fromDate,
                    toDate,
                    billType,
                    temporaryUrl,
                    totalCharges: { grossTotal },
                  },
                }) => ({
                  ...(!requestPrintedBillEnabled && {
                    issuedDate: issuedDate && (
                      <Typography textWrap="nowrap">
                        {formatFullDateShortMonth(new Date(issuedDate))}
                      </Typography>
                    ),
                  }),
                  billingPeriod: fromDate && toDate && (
                    <Typography>{billingPeriod(fromDate, toDate)}</Typography>
                  ),
                  totalCharges: grossTotal && (
                    <Typography component="p" variant="h3">
                      {chargeAmount(grossTotal)}
                    </Typography>
                  ),
                  downloadLink: billType && id && temporaryUrl && (
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      gap="xxs"
                    >
                      <Link
                        href={INTERNAL_PATHS.SINGLE_BILL_DOWNLOAD.createPath({
                          billType,
                          id,
                        })}
                        target="_blank"
                      >
                        View PDF
                      </Link>
                    </Stack>
                  ),
                  ...(requestPrintedBillEnabled && {
                    moreOptions: (
                      <EllipsisDropdown
                        items={[
                          {
                            icon: <IconFile size={16} />,
                            label: 'Order paper copy',
                            onClick: () => setBillDetails({ billType, id }),
                          },
                        ]}
                      />
                    ),
                  }),
                })
              )}
            />
          </Visibility>
          <Visibility md={{ display: 'none' }}>
            <Table
              columns={[
                {
                  name: '',
                  width: 90,
                },
                {
                  name: '',
                  width: 10,
                },
              ]}
              data={paginatedData.map(
                ({
                  node: {
                    issuedDate,
                    fromDate,
                    toDate,
                    billType,
                    id,
                    totalCharges: { grossTotal },
                  },
                }) => ({
                  left: (
                    <Stack direction="vertical">
                      {requestPrintedBillEnabled && grossTotal ? (
                        <Typography component="p" variant="h3">
                          {chargeAmount(grossTotal)}
                        </Typography>
                      ) : (
                        issuedDate && (
                          <Typography component="p" variant="h3">
                            {formatFullDateShortMonth(new Date(issuedDate))}
                          </Typography>
                        )
                      )}
                      {fromDate && toDate && (
                        <Typography
                          {...(grossTotal && {
                            variant: 'body2',
                          })}
                        >
                          {billingPeriod(fromDate, toDate)}
                        </Typography>
                      )}
                      <Stack alignItems="center" gap="xxs">
                        <IconFile size={16} />
                        <Link
                          href={INTERNAL_PATHS.SINGLE_BILL_DOWNLOAD.createPath({
                            billType,
                            id,
                          })}
                          target="_blank"
                        >
                          View PDF
                        </Link>
                      </Stack>
                    </Stack>
                  ),
                  right: requestPrintedBillEnabled ? (
                    <EllipsisDropdown
                      items={[
                        {
                          icon: <IconFile size={16} />,
                          label: 'Order paper copy',
                          onClick: () => setBillDetails({ billType, id }),
                        },
                      ]}
                    />
                  ) : (
                    grossTotal && (
                      <Typography component="p" variant="h3" textAlign="right">
                        {chargeAmount(grossTotal)}
                      </Typography>
                    )
                  ),
                })
              )}
            />
          </Visibility>
          <Paginator
            buttonLabel="Show more bills"
            totalNumberOfItems={totalNumberOfItems}
            numberOfItemsDisplayed={numberOfDisplayedItems}
            hasNextPage={hasNextPage}
            onButtonClick={fetchNextPage}
          />
        </>
      ) : (
        <NoDataMessage
          title="No bills available"
          body="Previous bills cannot be displayed - please come back when you receive a new bill"
        />
      )}
      {billDetails && (
        <OrderPrintedBillDialog
          dialogOpen
          onOrderPrintedCopy={handleOrderPrintedCopy}
          onClose={() => setBillDetails(null)}
          billType={billDetails.billType}
          id={billDetails.id}
          isLoading={isRequestPrintedBillLoading}
        />
      )}
    </>
  );
};

const LoadingState = () => (
  <>
    <Visibility display="none" md={{ display: 'block' }}>
      <Table
        isLoading
        columns={[
          {
            name: '',
            width: 10,
          },
          {
            name: '',
            width: 60,
          },
          {
            name: '',
            width: 10,
          },
          {
            name: '',
            width: 10,
          },
        ]}
      />
    </Visibility>

    <Visibility md={{ display: 'none' }}>
      <Table
        columns={[
          {
            name: '',
            width: 75,
          },
          {
            name: '',
            width: 25,
          },
        ]}
        data={[
          {
            left: <Body2Skeleton />,
            right: <Body2Skeleton />,
          },
          {
            left: <Body2Skeleton />,
            right: <Body2Skeleton />,
          },
        ]}
      />
    </Visibility>
  </>
);

export default BillsList;
