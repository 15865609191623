import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Grid, Stack, Typography } from '@krakentech/coral';
import { getCookie, setCookie } from 'cookies-next';

import {
  AccountEnrichmentCard,
  AuthenticatedPageLayout,
  BalanceCard,
  DashboardGreeting,
  DirectDebitActionCard,
  HomeMoveCard,
  LatestBillCard,
  MakeAOneOffPaymentCard,
  MoveOutCard,
  NextPaymentCard,
  ParentPageLayout,
  PaymentFeaturesComingSoonBanner,
  SubmitNewReadingCard,
  UpcomingPaymentSchedule,
} from '@/components';
import PSREnrichmentCard from '@/components/overview/PSREnrichmentCard';
import { ANIMATED_ALERT_STATUS } from '@/consts/animatedAlertStatus';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { usePsrRenewalDue } from '@/hooks/accounts/usePsrRenewalDue';
import { useHasNotMovedOut } from '@/hooks/moveHome/useHasNotMovedOut';
import { useOccupancyPeriods } from '@/hooks/moveHome/useOccupancyPeriods';
import { useWillNotMoveOutInFuture } from '@/hooks/moveHome/useWillNotMoveOutInFuture';
import { useLatestReading } from '@/hooks/usage/useLatestReading';
import {
  useFeatureFlag,
  useSyncFeatureFlag,
} from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';
import { closeAnimatedAlert } from '@/utils/alerts';
import { COOKIES } from '@/utils/cookies';
import { INTERNAL_PATHS } from '@/utils/urls';

export const Dashboard = () => {
  const { query } = useRouter();

  const [numberOfDesktopBalanceColumns, setNumberOfDesktopBalanceColumns] =
    useState(1);

  const { data: hasNotMovedOut, isFetched: hasNotMovedOutFetched } =
    useHasNotMovedOut();
  const {
    data: willNotMoveOutInTheFuture,
    isFetched: willNotMoveOutInTheFutureFetched,
  } = useWillNotMoveOutInFuture();
  const { data: occupancyPeriodsData } = useOccupancyPeriods();
  const { data: isPSRRenewalDue, refetch: refetchPsrRenewalDue } =
    usePsrRenewalDue();

  const [alertStatus, setAlertStatus] = useState(ANIMATED_ALERT_STATUS.CLOSED);
  const [alertMessage, setAlertMessage] = useState('');

  const message = useMemo(() => {
    if (query.contactDetailsSuccess) {
      return 'Your personal details have been updated';
    } else if (query.priorityServicesRegisterSuccess) {
      return 'Your Priority Services requirement has been updated';
    }
    return undefined;
  }, [query]);

  useEffect(() => {
    setAlertMessage(message || '');
    setAlertStatus(
      message ? ANIMATED_ALERT_STATUS.OPEN : ANIMATED_ALERT_STATUS.CLOSED
    );
  }, [message]);

  const showOnlinePayment = useFeatureFlag(FeatureNames.OnlinePayment);
  const showDirectDebit = useFeatureFlag(FeatureNames.DirectDebit);
  const showHomeMove = useFeatureFlag(FeatureNames.HomeMove);
  const showPSRRenewal = useFeatureFlag(FeatureNames.PSRRenewal);

  const { accountNumber } = useAccountNumberContext();

  const { data: latestReadingData } = useLatestReading({ accountNumber });

  const { SNOOZE_PSR_UPDATE } = COOKIES;

  const [isSnoozePsrUpdate, setIsSnoozePsrUpdate] = useState(
    getCookie(SNOOZE_PSR_UPDATE) === 'true'
  );

  const changePaymentScheduleEnabled = useSyncFeatureFlag(
    FeatureNames.ChangePaymentSchedule
  );

  const showUpcomingPaymentScheduleAlert =
    showDirectDebit && !changePaymentScheduleEnabled;

  const showHomeMoveCard =
    showHomeMove && hasNotMovedOut && willNotMoveOutInTheFuture;

  const movingOutDate =
    occupancyPeriodsData?.properties[0]?.occupancyPeriods[0]?.effectiveTo;

  const isInMovingProcess =
    (hasNotMovedOutFetched && !hasNotMovedOut) ||
    (willNotMoveOutInTheFutureFetched && !willNotMoveOutInTheFuture);

  const showDirectDebitCard =
    showDirectDebit && hasNotMovedOut && willNotMoveOutInTheFuture;

  const activeWaterMeters =
    latestReadingData?.properties[0]?.activeWaterMeters.length || 0;

  const hasOneActiveMeter = activeWaterMeters === 1;

  const isInMovingProcessWithoutActiveMeter =
    !activeWaterMeters && isInMovingProcess;

  const showSubmitNewReadingCard =
    !isInMovingProcessWithoutActiveMeter &&
    (hasOneActiveMeter || !activeWaterMeters);

  const showPSREnrichmentCard =
    showPSRRenewal && isPSRRenewalDue && hasNotMovedOut && !isSnoozePsrUpdate;

  const showAccountEnrichmentCard =
    hasNotMovedOutFetched && hasNotMovedOut && !showPSREnrichmentCard;

  useEffect(() => {
    if (isSnoozePsrUpdate) setCookie(SNOOZE_PSR_UPDATE, true);
  }, [SNOOZE_PSR_UPDATE, isSnoozePsrUpdate]);

  useEffect(() => {
    if (showPSRRenewal) refetchPsrRenewalDue();
  }, [showPSRRenewal, refetchPsrRenewalDue]);

  return (
    <>
      <ParentPageLayout
        pageHeading={<DashboardGreeting />}
        alertMessage={alertMessage}
        alertStatus={alertStatus}
        handleAlertClose={() => closeAnimatedAlert(setAlertStatus)}
      >
        <Stack
          direction="vertical"
          gap="md"
          md={{ gap: 'lg', direction: 'vertical' }}
        >
          {isInMovingProcess && (
            <MoveOutCard {...(movingOutDate && { movingOutDate })} />
          )}

          <PaymentFeaturesComingSoonBanner />

          {showUpcomingPaymentScheduleAlert && <UpcomingPaymentSchedule />}

          {showAccountEnrichmentCard && <AccountEnrichmentCard />}

          {showPSREnrichmentCard && (
            <PSREnrichmentCard onCardClose={() => setIsSnoozePsrUpdate(true)} />
          )}

          <Grid
            fullWidth
            gap="smMd"
            templateColumns={`repeat(1, 1fr)`}
            sm={{
              gap: 'md',
              templateColumns: 'repeat(1, 1fr)',
            }}
            md={{
              gap: 'md',
              templateColumns: 'repeat(1, 1fr)',
            }}
            lg={{
              gap: 'md',
              templateColumns: `repeat(${numberOfDesktopBalanceColumns}, 1fr)`,
            }}
          >
            <Grid.Item>
              <BalanceCard />
            </Grid.Item>

            <Grid.Item>
              {showDirectDebit && (
                <NextPaymentCard
                  setNumberOfDesktopColumns={setNumberOfDesktopBalanceColumns}
                />
              )}
            </Grid.Item>
          </Grid>

          <Stack gap="sm" md={{ gap: 'md' }} direction="vertical">
            <Typography variant="h2">Quick links</Typography>

            <Grid
              templateColumns={'repeat(1, 1fr)'}
              gap="sm"
              md={{
                gap: 'md',
                templateColumns: 'repeat(1, 1fr)',
              }}
              lg={{
                templateColumns: 'repeat(2, 1fr)',
              }}
            >
              {showDirectDebitCard && <DirectDebitActionCard />}

              {showOnlinePayment && <MakeAOneOffPaymentCard />}

              <LatestBillCard />

              {showSubmitNewReadingCard && <SubmitNewReadingCard />}

              {showHomeMoveCard && <HomeMoveCard />}
            </Grid>
          </Stack>
        </Stack>
      </ParentPageLayout>
    </>
  );
};

Dashboard.getLayout = (page: ReactNode) => (
  <AuthenticatedPageLayout pageTitle={INTERNAL_PATHS.HOME.title}>
    {page}
  </AuthenticatedPageLayout>
);

export default Dashboard;
