import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

import { useAuth } from '@/components/Providers/Auth';
import { useAnalytics } from '@/hooks/utils/useAnalytics';

const AdobeAnalytics = () => {
  const router = useRouter();
  const {
    trackPageView,
    adobe: { enabled: adobeAnalyticsEnabled, ready: adobeAnalyticsReady },
  } = useAnalytics();
  const { hasLoaded } = useAuth();

  const trackPageViewForAdobe = useCallback(() => {
    if (adobeAnalyticsEnabled && window._satellite) {
      const delayedTrack = setTimeout(() => {
        // Tracks every page view including steppers
        window._satellite.track('page_step_changed');
      }, 3000); // Delay by 3 seconds which allows enough time for the window.digitalData to be up to date prior to tracking

      // Cleanup function to clear the timeout
      return () => {
        clearTimeout(delayedTrack);
      };
    }
  }, [adobeAnalyticsEnabled]);

  /**
   * Handle route / hash changes in the browser
   * and log those in Adobe Analytics.
   */
  useEffect(() => {
    const handleRouteChange = () => {
      trackPageView();
      trackPageViewForAdobe();
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events, trackPageView, trackPageViewForAdobe, router.asPath]);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof document !== 'undefined' &&
      hasLoaded
    ) {
      trackPageView();
    }
  }, [adobeAnalyticsEnabled, adobeAnalyticsReady, hasLoaded, trackPageView]);

  return null;
};

export default AdobeAnalytics;
