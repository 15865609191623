import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { setUser as setSentryUser } from '@sentry/browser';

import { useViewerId } from '@/hooks/accounts/useViewerId';

const AnalyticsUser = () => {
  const { data: userId } = useViewerId();

  useEffect(() => {
    if (userId) {
      // Set the user ID in Sentry
      setSentryUser({ id: userId });

      // Set the user ID in Datadog
      datadogRum.setUser({
        id: userId,
      });
    }
  }, [userId]);

  return null;
};

export default AnalyticsUser;
